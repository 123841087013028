import CryptoJS from "crypto-js";
import { secretKey } from "../utils/api/ApiUrl";

export const encryptUserId = (userId) => {
  // Encrypt the user ID using AES
  const ciphertext = CryptoJS.AES.encrypt(
    userId.toString(),
    secretKey
  ).toString();

  // Convert ciphertext to a Base64 representation and sanitize to include only letters and numbers
 const sanitizedCiphertext = btoa(ciphertext).replace(/[^a-zA-Z0-9]/g, "");

  // Return the sanitized ciphertext
  return sanitizedCiphertext;
};


// Function to decrypt user ID
export const decryptUserId = (encryptedId) => {
  try {
    // Decode the Base64 string back into the original ciphertext
    const decodedCiphertext = atob(encryptedId);

    // Decrypt the decoded ciphertext
    const bytes = CryptoJS.AES.decrypt(decodedCiphertext, secretKey);

    const originalId = bytes.toString(CryptoJS.enc.Utf8);

    return originalId;
  } catch (error) {
    console.error("Failed to decrypt the user ID:", error);
    return null;
  }
};


