import * as React from "react";
import {
  Typography,
  AppBar,
  Stack,
  Avatar,
  CssBaseline,
  Drawer,
  Box,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Backdrop,
  CircularProgress,
  IconButton,
  container,
  // Snackbar,
  // Alert,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Outlet, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/Jevebara.png";
import { Icon } from "@iconify/react";
import { useState, useEffect, useCallback } from "react";
import { getCookie } from "cookies-next";
import { deleteCookie } from "cookies-next";
import MenuIcon from "@mui/icons-material/Menu";
import Badge from "@mui/material/Badge";
import Api from "../../services/http/api";
import axios from "axios";
// import { useSelector } from "react-redux";
// import { reducerUserData } from "../../reducers/sessionReducer";


const drawerWidth = 320;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: "210px",
    height: "52px",
    // color: "#FFF",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function BasicLayout() {
  // const localUserData = useSelector(reducerUserData);
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState("Accueil");
  const [stat, setStat] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkUserSession = useCallback(() => {
    const token = sessionStorage.getItem("token");
    const expiryTime = new Date(getCookie("tokenBARA_expires")).getTime();
    const currentTime = new Date().getTime();

    if (!token) {
      navigate("/");
      return;
    } else if (currentTime > expiryTime) {
      navigate("/");
    } 
  }, [navigate]);

const getNew = async () => {
  try {
    const response = await axios({
      url: `${Api.baseUrl}/api/v3/admin-all/stats/count/new`,
      method: "GET",
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
    setStat(response.data.content);
  } catch (error) {
    console.log(error.response);
  }
};

  useEffect(() => {
    getNew();
    checkUserSession();
  },[]);

  const deleteLocalData = async () => {
    localStorage.removeItem("userData");
    sessionStorage.removeItem("token");
    deleteCookie("tokenBARA");
    deleteCookie("role");
    deleteCookie("nom");
  };

  const logout = async () => {
    setOpenBackdrop(true);

    try {
      await deleteLocalData();
      setOpenBackdrop(false);
      navigate("/");
      // reload();
    } catch (err) {
      console.error(err);
    }
  };

  const handleItemClick = (item) => {
    setSelectedItem(item.name);
    localStorage.setItem("selectedItem", item.name);
    localStorage.setItem("route", item.url);
    navigate(item.url);
  };
  const canViewRetraits = (role) => role === "FINANCIER" || role === "ADMIN";
  const canViewTransactions = (role) =>
    ["FINANCIER", "MANAGER", "ADMIN"].includes(role);

  const navItemsUp = [
    {
      name: "Accueil",
      icon: "solar:home-smile-linear",
      url: "",
      count: 0,
    },
    {
      name: "Grouilleurs",
      icon: "ri:group-line",
      url: "grouilleurs/",
      count: 0,
    },
    {
      name: "Bara",
      icon: "bi:briefcase",
      url: "bara/",
      count: stat === null ? 0 : stat.offres,
    },
    {
      name: "Badges",
      icon: "fluent:document-one-page-multiple-16-filled",
      url: "badges/",
      count: stat === null ? 0 : stat.badges,
    },
    ...(canViewRetraits(getCookie("role"))
      ? [
          {
            name: "Retraits",
            icon: "bi:briefcase",
            url: "retraits/",
            count: stat === null ? 0 : stat.retrait,
          },
        ]
      : []),
    ...(canViewTransactions(getCookie("role"))
      ? [
          {
            name: "Transactions",
            icon: "tabler:database-dollar",
            url: "transactions/",
            count: 0,
          },
        ]
      : []),
    {
      name: "Messages",
      icon: "ep:chat-dot-round",
      url: "messages/",
      count: 0,
    },
  ];
  const navItemsDown = [
    // {
    //   name: "Promotions",
    //   icon: "tabler:speakerphone",
    //   url: "promotions/",
    // },
    // {
    //   name: "Corbeille de tâches",
    //   icon: "carbon:recycle",
    //   url: "corbeille/",
    // },
    {
      name: "Paramètres",
      icon: "bi:gear",
      url: "parametres/",
    },
  ];
  const drawer = (
    <div>
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "end",
        }}
      >
        <Box
          sx={{
            px: 2,
            width: "80%",
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Box sx={{ py: 2, alignContent: "start" }}>
            <img src={Logo} alt="Logo" />
          </Box>
        </Box>
        {navItemsUp.map((item) => (
          <ListItem key={item.name} sx={{ py: 0, width: "90%", pr: 0 }}>
            <ListItemButton
              selected={item.name === selectedItem}
              onClick={() => {
                navigate(item.url);
                handleItemClick(item);
                // console.log(localUserData.token);
              }}
              disableRipple
              sx={{
                height: "57px",
                borderRadius: "50px 0 0 50px",
                position: "relative",

                "&.Mui-selected": {
                  backgroundColor: "rgba(249, 249, 249, 1)",
                  ":hover": {
                    background: "rgba(249, 249, 249, 1)",
                    borderRadius: "50px 0 0 50px",
                  },
                  "&::after": {
                    content: "''",
                    height: "2em",
                    width: "2em",
                    position: "absolute",
                    bottom: "-2em",
                    right: "0em",
                    borderRadius: "0 18px 0 0",
                    boxShadow: "0 -16px 0 0 rgba(249, 249, 249, 1)",
                  },
                  "&::before": {
                    content: "''",
                    height: "2em",
                    width: "2em",
                    position: "absolute",
                    top: "-2em",
                    right: "0em",
                    borderRadius: "0 0 18px 0",
                    boxShadow: "0 16px 0 0 rgba(249, 249, 249, 1)",
                  },
                },
                "&.Mui-focusVisible": {
                  backgroundColor: "rgba(249, 249, 249, 1)",
                },
                ":hover": {
                  background: "none",
                  borderRadius: "50px 40px 40px 50px",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "24px",
                }}
              >
                <Badge badgeContent={item.count} color="secondary">
                  <Icon
                    icon={item.icon}
                    style={{
                      color: item.name === selectedItem ? "#00833D" : "white",
                    }}
                  />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                primaryTypographyProps={{ fontSize: "14px" }}
                sx={{
                  color: item.name === selectedItem ? "#00833D" : "white",
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider color="#FFF" sx={{ width: "80%", alignSelf: "center" }} />

      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "end",
        }}
      >
        {navItemsDown.map((item) => (
          <ListItem key={item.name} sx={{ py: 0, width: "90%", pr: 0 }}>
            <ListItemButton
              selected={item.name === selectedItem}
              onClick={() => {
                navigate(item.url);
                handleItemClick(item);
              }}
              disableRipple
              sx={{
                height: "57px",
                borderRadius: "50px 0 0 50px",
                position: "relative",

                "&.Mui-selected": {
                  backgroundColor: "rgba(249, 249, 249, 1)",
                  ":hover": {
                    background: "rgba(249, 249, 249, 1)",
                    borderRadius: "50px 0 0 50px",
                  },
                  "&::after": {
                    content: "''",
                    height: "2em",
                    width: "2em",
                    position: "absolute",
                    bottom: "-2em",
                    right: "0em",
                    borderRadius: "0 20px 0 0",
                    boxShadow: "0 -16px 0 0 #ffffff",
                  },
                  "&::before": {
                    content: "''",
                    height: "2em",
                    width: "2em",
                    position: "absolute",
                    top: "-2em",
                    right: "0em",
                    borderRadius: "0 0 20px 0",
                    boxShadow: "0 16px 0 0 #ffffff",
                  },
                },
                "&.Mui-focusVisible": {
                  backgroundColor: "none",
                },
                ":hover": {
                  background: "none",
                  borderRadius: "50px 40px 40px 50px",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "24px",
                }}
              >
                <Icon
                  icon={item.icon}
                  style={{
                    color: item.name === selectedItem ? "#00833D" : "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                primaryTypographyProps={{ fontSize: "14px" }}
                sx={{
                  color: item.name === selectedItem ? "#00833D" : "white",
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <Backdrop
        sx={{
          color: "primary.main",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Snackbar
        open={openN}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar> */}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          color="ternary"
          position="fixed"
          elevation={0}
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Toolbar />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}></Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              mx: 0,
              my: 1,
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mx: 4,
                justifyContent: "flex-end",
              }}
              gap={4}
            >
              <Stack direction="row" gap={4}>
                <Avatar
                  sx={{ background: "#F8F8F8", width: "45px", height: "45px" }}
                >
                  <Icon icon="tabler:bell" style={{ color: "#9BA69C" }} />
                </Avatar>
                <Avatar
                  sx={{ background: "#F8F8F8", width: "45px", height: "45px" }}
                >
                  <Icon icon="ep:chat-dot-round" style={{ color: "#9BA69C" }} />
                </Avatar>
              </Stack>
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="outlined"
                disableRipple
                elevation={2}
                onClick={handleClick}
                endIcon={
                  <Icon
                    icon="mdi:arrow-down-drop"
                    style={{ color: "9BA69C" }}
                  />
                }
                sx={{ borderColor: "#F8F8F8", textTransform: "none" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  gap={2}
                >
                  <Typography
                    component="div"
                    sx={{ color: "#000000", fontWeight: "500" }}
                  >
                    <Box>{getCookie("nom")}</Box>
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      sx={{
                        background: "#F8F8F8",
                        width: "45px",
                        height: "45px",
                      }}
                    >
                      <Icon
                        icon="ion:person-outline"
                        style={{ color: "9BA69C" }}
                      />
                    </Avatar>
                  </Stack>
                </Box>
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={logout} disableRipple sx={{ gap: 1 }}>
                  <Icon icon="mdi-light:logout" style={{ color: "9BA69C" }} />
                  Déconnexion
                </MenuItem>
              </StyledMenu>
            </Box>
          </Box>
          <Divider />
        </AppBar>

        <Drawer
          elevation={0}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            display: { display: "flex", xs: "none", sm: "block" },
            justifyContent: "center",
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              background: "#00833D",
              borderRight: "none",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#00833D",
              borderRight: "none",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Box component="main" sx={{ width: "100%", mt: 1 }}>
          <Outlet context={{ getNew }} />
        </Box>
      </Box>
    </>
  );
}
