import React, { useState, useRef } from "react";
import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
} from "@mui/material";
import Logo from "../../../../assets/img/logo.png";
import Api from "../../../../services/http/api";
import { AppButton } from "../../../../assets/styles/Widgets";
import CustomToast from "../../../../helpers/customToast";
import { encryptUserId } from "../../../../services/encrypt";
import { Env } from "../../../../Env";

const IDCard = ({ userData, open, handleClose }) => {
  // console.log(userData);

  const elementRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const encryptedId = encryptUserId(userData.idUser);
  // Local
  // const encryptedId = userData.idUser;

  // Production
  const encodedUserId = encryptedId;

  const url = `${Env.appUrl}/grouilleur/${encodedUserId}`;
  const link = url;

  const handleDownloadImage = async () => {
    setIsLoading(true);
    const element = elementRef.current;
    if (!element) return;

    try {
      const canvas = await html2canvas(element, {
        useCORS: true,
        allowTaint: false,
        scale: 2,
      });
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = `${userData.nom} ${userData.prenom}-Badge.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      CustomToast.showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            backgroundColor: "#ffffff",
          }}
        >
          <Card
            ref={elementRef}
            sx={{
              width: 400,
              // borderRadius: 3,
              boxShadow: 5,
              overflow: "hidden",
              border: "1px solid #ddd",
              p: 1,
            }}
          >
            <Box
              sx={{
                background:
                  "linear-gradient(210deg, #FFFFFF 35.12%, #00833D 45.88%)",
                borderRadius: 2,
              }}
            >
              {/* Top Section */}
              <Box
                sx={{
                  backgroundColor: "#fffff",
                  color: "#fff",
                  textAlign: "center",
                  padding: "20px 10px",
                }}
              >
                <img
                  src={Logo} // Replace with your logo URL
                  alt="Company Logo"
                  style={{ width: "100px", paddingBottom: "10px" }}
                />
                {/* <Typography
                variant="h6"
                fontSize="18px"
                fontWeight="bold"
                color="#36454F"
              >
                COGIT BUSINESS 
              </Typography> */}
                {/* <Typography
                variant="body2"
                sx={{ fontSize: "14px", fontWeight: "500", color: "#36454F" }}
              >
                contact@monsiteamoi.net
              </Typography> */}
              </Box>

              {/* Profile Section */}
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Avatar
                  src={`${Api.baseUrl}/api/file?fileReference=${userData.photoProfil}`}
                  alt="Profile Picture"
                  sx={{
                    width: 180,
                    height: 180,
                    margin: "0 auto",
                    border: "6px solid #fff",
                  }}
                />
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ marginTop: 2, color: "#FFFFFF" }}
                >
                  {userData.nom} {userData.prenom}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", fontWeight: "600", color: "#FFFFFF" }}
                >
                  {userData.categories.length !== 0
                    ? userData.categories[0].libelle 
                    : ""}
                </Typography>
              </Box>

              {/* Info Section */}
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  spacing={1}
                  sx={{
                    color: "#374151",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {[
                    { label: "Matricule", value: userData.matricule },
                    {
                      label: "Address",
                      value: userData.adresse,
                    },
                    {
                      label: "Parrainage",
                      value: userData.codeParrainage,
                    },
                  ].map((info, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={4}>
                        <Typography
                          variant="body2"
                          sx={{ color: "#FFFFFF", fontWeight: "500" }}
                        >
                          {info.label}:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            color: "#FFFFFF",
                            fontWeight: "500",
                          }}
                        >
                          {info.value}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </CardContent>

              {/* QR Code Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px 10px",
                  gap: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "14px",
                    color: "#FFFFFF",
                    fontWeight: "500",
                  }}
                >
                  Scan ici pour voir mon profil{" "}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 0.6,
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <QRCode value={link} size={120} />
                </Box>
              </Box>
            </Box>
          </Card>
          <Box sx={{ p: 2 }}>
            <AppButton onClick={handleDownloadImage} disabled={isLoading}>
              Télécharger
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#FFFFF",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </AppButton>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default IDCard;
