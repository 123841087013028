import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Divider,
  CircularProgress,
  Fab,
  LinearProgress,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import Logo from "../../assets/img/logo.png";
import ProfilePicture from "./components/ProfilePicture";
import Realisation from "./components/Realisation";
import ImageViewer from "./components/ImageViewer";
import Review from "./components/Reviews";
import Badges2 from "./components/Badges2";
import Api from "../../services/http/api";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DisplayContact from "./components/DisplayContact";
import { decryptUserId } from "../../services/encrypt";
import axios from "axios";
import CustomToast from "../../helpers/customToast";
import { Icon } from "@iconify/react";

// ValidatedTextField.js
const ValidatedTextField = ({
  label,
  validator,
  onChange,
  type,
  callback,
  value,
  required = false,
  rows = 1,
}) => {
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.value;
    const errorMessage = validator(newValue);
    callback(newValue);
    setError(errorMessage);
    onChange(!errorMessage);
  };
  return (
    <TextField
      required={required}
      rows={rows}
      multiline={rows > 1}
      type={type}
      label={label}
      value={value}
      onChange={handleChange}
      error={!!error}
      helperText={error}
    />
  );
};

// validators.js
const fieldValidator = (value) => {
  if (value > 10) return "Une compétence est notée sur 10 points";
  if (value === null) return "Vuillez ajoutez une note";
  return false;
};

const ProfileGrouilleur = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openAvis, setOpenAvis] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const { userId } = useParams(); // Get the userId from the URL
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [ponctualite, setPonctualite] = useState("");
  const [travail, setTravail] = useState("");
  const [proff, setProff] = useState("");
  const [commentaire, setCommentaire] = useState("");
  const [rigueur, setRigueur] = useState("");
  const formValid = useRef({
    ponctualite: false,
    travail: false,
    proff: false,
    rigueur: false,
    commentaire: false,
  });

  const handleInputChange = (event, callback) => {
    setCommentaire(event.target.value);
  };

  const [selectedIndex, setSelectedIndex] = useState(null);
  const isXs = useMediaQuery(theme.breakpoints.down("sm")); // For xs screen sizes

  // Production
  const decryptedUserId = decryptUserId(userId);

  // Local
  // const decryptedUserId = userId;

  const noter = async () => {
    if (
      ponctualite.trim().length === 0 ||
      proff.trim().length === 0 ||
      travail.trim().length === 0 ||
      commentaire.trim().length === 0 ||
      rigueur.trim().length === 0
    ) {
      CustomToast.showWarnin("Veuillez saisir une note pour tous les 5 champs");
    } else if (
      ponctualite.trim() > 10 ||
      proff.trim() > 10 ||
      travail.trim() > 10 ||
      rigueur.trim() > 10
    ) {
      CustomToast.showWarnin("Une compétence est notée sur 10 points");
    } else {
      setIsLoading(true);
      const postData = {
        contenu: commentaire,
        ponctualite: ponctualite,
        professionnalisme: proff,
        qualiteTravail: travail,
        rigueur: rigueur,
      };

      try {
        const response = await axios({
          url: `${Api.baseUrl}/api/avis/${userData.id}/noter`,
          method: "POST",
          data: postData,
        });

        CustomToast.showSuccess(response.data.message);
        setCommentaire("");
        setTravail("");
        setRigueur("");
        setProff("");
        setPonctualite("");
        handleCloseAvis();
      } catch (error) {
        console.log(error);
        CustomToast.showError("Opération Echoué");
      } finally {
        await fetchUserProfile(decryptedUserId);
        setIsLoading(false);
      }
    }
  };

  const handleClose = () => {
    setSelectedIndex(null);
    setOpen(false);
  };
  const handleOpen = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };
  const handleCloseAvis = () => {
    setOpenAvis(false);
  };
  const handleOpenAvis = () => {
    setOpenAvis(true);
  };

  const handleCloseContact = () => {
    setOpenContact(false);
  };

  useEffect(() => {
    if (decryptedUserId) {
      fetchUserProfile(decryptedUserId);
    } else {
      console.error("Decryption of userId failed.");
      setLoading(false);
    }
  }, [decryptedUserId]);

  const fetchUserProfile = async (userId) => {
    setLoading(true);
    // Use the decrypted userId here
    try {
      const response = await fetch(
        `${Api.baseUrl}/api/mobile/v3/utilisateur/${userId}/profile`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }
      const data = await response.json();
      console.log("userdata:", data.content);
      setUserData(data.content);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          bgcolor: "background.default",
          px: 2,
          py: 3,
        }}
      >
        <Box
          sx={{ maxWidth: "1000px", textAlign: { xs: "center", md: "left" } }}
        >
          {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems:"center" }}> */}

          <Fab
            component="a"
            variant="extended"
            color="primary"
            aria-label="phone"
            sx={{
              position: "fixed",
              bottom: isXs && 100,
              top: !isXs && 150,
              right: 16,
              textTransform: "none",
            }}
            href={`tel:${userData.telephone}`}
            // onClick={handleOpenContact}
          >
            <CallIcon sx={{ mr: isXs ? 0 : 1 }} />
            {!isXs && "Contacter"}
          </Fab>
          <Fab
            variant="extended"
            color="primary"
            aria-label="phone"
            sx={{
              position: "fixed",
              bottom: isXs && 45,
              top: !isXs && 205,
              right: 16,
              textTransform: "none",
              display: "flex",
              gap: 1,
            }}
            onClick={handleOpenAvis}
          >
            <Icon icon="emojione-monotone:pen" style={{ fontSize: "24px" }} />
            {/* <AddIcon sx={{ mr: isXs ? 0 : 1 }} /> */}
            {!isXs && "Avis"}
          </Fab>
          <Box sx={{ pb: 5 }}>
            <img src={Logo} alt="Logo" style={{ height: "70px" }} />
          </Box>

          <ProfilePicture user={userData} />

          <Box>
            <Badges2 badges={userData.badges} avis={userData.avis} />
            <Divider sx={{ my: 2 }} />
            <Realisation
              images={userData.realisations}
              handleOpen={handleOpen}
            />
            <ImageViewer
              open={open}
              close={handleClose}
              images={userData.realisations}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
            />

            <Review avis={userData.avis} />
            <DisplayContact
              open={openContact}
              handleClose={handleCloseContact}
              contact={userData.telephone}
            />
          </Box>
        </Box>
      </Box>
      <Dialog open={openAvis} onClose={handleCloseAvis}>
        {isLoading ? <LinearProgress></LinearProgress> : null}
        <DialogTitle>Noter le grouilleur</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{ "& .MuiTextField-root": { m: 1, width: "30ch" } }}
            noValidate
            autoComplete="off"
          >
            <DialogContentText>
              NB: Une compétence est notée sur 10 points
            </DialogContentText>
            <Box>
              <ValidatedTextField
                required={true}
                callback={setPonctualite}
                value={ponctualite}
                type={"number"}
                label="Ponctualité"
                validator={fieldValidator}
                onChange={(isValid) =>
                  (formValid.current.ponctualite = isValid)
                }
              />
              <ValidatedTextField
                required={true}
                callback={setProff}
                value={proff}
                type={"number"}
                label="Professionnalisme"
                validator={fieldValidator}
                onChange={(isValid) => (formValid.current.proff = isValid)}
              />
              <ValidatedTextField
                required={true}
                callback={setRigueur}
                value={rigueur}
                type={"number"}
                label="Rigueur"
                validator={fieldValidator}
                onChange={(isValid) => (formValid.current.rigueur = isValid)}
              />
              <ValidatedTextField
                required={true}
                callback={setTravail}
                value={travail}
                type={"number"}
                label="Qualité de travail"
                validator={fieldValidator}
                onChange={(isValid) => (formValid.current.travail = isValid)}
              />
            </Box>
          </Box>

          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: isXs ? "30ch" : "62ch" },
              pt: 6,
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="standard-multiline-static"
              multiline
              required
              type={"text"}
              label="Commentaire"
              value={commentaire}
              rows={4}
              onChange={handleInputChange}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            sx={{ textTransform: "capitalize" }}
            onClick={handleCloseAvis}
          >
            Annuler
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            type="submit"
            onClick={noter}
          >
            Noter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileGrouilleur;
