import React from "react";
import { Box, Avatar, Typography, Divider, Rating } from "@mui/material";
import Image from "../../../assets/img/profile.png";
import { TextBox1 } from "../../../assets/styles/Widgets";
import { Icon } from "@iconify/react";
import Api from "../../../services/http/api";

const ProfilePicture = ({ user }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "left" },
          gap: 1,
        }}
      >
        <Avatar
          sx={{
            height: "260px",
            width: "260px",
            bgcolor: "white",
            border: "2px solid #00833D",
            mb: 1,
          }}
        >
          {
            <Avatar
              sx={{
                height: "250px",
                width: "250px",
                bgcolor: "white",
              }}
              alt="Photo de profil"
              src={`${Api.baseUrl}/api/file?fileReference=${user.photoProfil}`}
            />
          }
        </Avatar>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "left" },
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "700", pb: 1 }}>
              {user.nom} {user.prenom}
            </Typography>
            {user.certifier ? (
              <Icon
                icon="solar:verified-check-bold"
                width="24"
                height="24"
                style={{ color: "#0b7cea" }}
              />
            ) : null}
          </Box>
          <Typography variant="body2" sx={{ fontWeight: "400", pb: 1 }}>
            Matricule: {user.matricule}
          </Typography>
          <Rating
            name="size-large"
            defaultValue={user.noteGlobale}
            precision={0.5}
            size="large"
            readOnly
          />

          <TextBox1 sx={{ pb: 1 }}>Catégorie</TextBox1>
          <Box
            sx={{
              maxWidth: { xs: "100%", md: "500px" },
              display: "flex",
              justifyContent: { xs: "center", md: "left" },
              gap: 0.5,
              pb: 1,
            }}
          >
            {user.categories.slice(0, 4).map((item, index) => (
              <Typography variant="body2" key={item.id}>
                {item.libelle}
                {index < 3 ? ", " : "."}
              </Typography>
            ))}
          </Box>

          <TextBox1 sx={{ pb: 1 }}>Présentation</TextBox1>
          <Box
            sx={{
              maxWidth: { xs: "100%", md: "500px" },
            }}
          >
            <Typography variant="body2">{user.presentation}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProfilePicture;
