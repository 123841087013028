import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import svg from "../../assets/svg/404.gif"
import { grey } from "@mui/material/colors";


const PageNotFound = () => {
   const navigate = useNavigate();

   const onGoBackToHome = () => {
     navigate("/");
   };
  return (
    <>
      <Box
        sx={{
          py: { xl: 8 },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            mb: { xs: 4, xl: 8 },
            width: "100%",
            maxWidth: { xs: 200, sm: 300, xl: 1000 },
            "& img": {
              width: "100%",
              maxWidth: 400,
            },
          }}
        >
          <img src={svg} alt={svg} />
        </Box>
        <Box
          sx={{
            mb: { xs: 4, xl: 5 },
          }}
        >
          <Box
            component="h3"
            sx={{
              mb: { xs: 2, xl: 3 },
              fontSize: { xs: 20, md: 24 },
              fontWeight: 500,
            }}
          >
            Oups! Page non trouvée.
          </Box>
          <Box
            sx={{
              mb: { xs: 4, xl: 5 },
              color: grey[600],
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            <Typography>Nous ne trouvons pas la page</Typography>
            <Typography>que vous cherchez.</Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontWeight: 500,
              fontSize: 16,
              textTransform: "capitalize",
            }}
            onClick={onGoBackToHome}
          >
            Retourner à l'Accueil
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PageNotFound;
