import React, { useState } from "react";
import { Box, Toolbar, Typography, Divider, Skeleton } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { Grid, Card } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import GroupIcon from "@mui/icons-material/Group";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import ImageL from "../../assets/img/confettiL.png";
import ImageR from "../../assets/img/confettR.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Api from "../../services/http/api";
import { useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { decryptUserId, encryptUserId } from "../../services/encrypt";

const data = [
  {
    icon: <LocalOfferIcon sx={{ color: "#FF7900" }} />,
    label: "Offre(s)",
    libelle: "offre",
  },
  {
    icon: <BeenhereIcon sx={{ color: "#FF7900" }} />,
    label: "Offre(s) Valide",
    libelle: "offreValide",
  },
  {
    icon: <GroupIcon sx={{ color: "#FF7900" }} />,
    label: "Grouilleurs(s)",
    libelle: "grouilleurs",
  },
  {
    icon: <HowToRegIcon sx={{ color: "#FF7900" }} />,
    label: "Grouilleurs(s) activé",
    libelle: "grouilleurActif",
  },
];
const style = {
  p: 0,
  width: "100%",
  borderRadius: 2,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
};

const StatsCard = ({ icon, label, value }) => (
  <Card
    elevation={0}
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 2,
      borderRadius: 2,
      boxShadow: 2,
      height: "100%",
      justifyContent: "center",
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 56,
        height: 56,
        borderRadius: "50%",
        backgroundColor: "#FFF2E7",
        mb: 2,
      }}
    >
      {icon}
    </Box>
    <Typography variant="body2" color="textSecondary">
      {label}
    </Typography>
    <Typography variant="h6">{value}</Typography>
  </Card>
);

const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);
  const [acquisition, setAcquisition] = useState([]);
  const [offer, setOffer] = useState([]);
  const [users, setUsers] = useState([]);
  const presentYear = dayjs().year();
  

  const chartSetting = {
    height: 500,
  };
 

  const valueFormatter = (value) => `${value}`;

  const statOffres = async () => {
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/stats/annee/grouilleur?year=${presentYear}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setUsers(response.data.content);
    } catch (error) {
      console.log(error.response);
    }
  };

  const statUsers = async () => {
    setUserLoading(true);
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/stats/count/elements`,
        method: "GET",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setOffer(response.data.content);
    } catch (error) {
      console.log(error.response);
    } finally {
      setUserLoading(false);
    }
  };
  const statAquistion = async () => {
    setLoading(true);
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/stats/count/grouilleur/acquisition`,
        method: "GET",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setAcquisition(response.data.content);
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    statOffres();
    statUsers();
    statAquistion();
  }, []);

  function getNombreByLibelle(libelle) {
    const item = acquisition.find((element) => element.libelle === libelle);
    return item ? item.nombre : null;
  }
  function getOfferNombreByLibelle(libelle) {
    const item = offer.find((element) => element.libelle === libelle);
    return item ? item.nombre : null;
  }
  function getIconByLibelle(libelle) {
    const item = data.find((element) => element.libelle === libelle);
    return item ? item.icon : null;
  }
  function getLabelByLibelle(libelle) {
    const item = data.find((element) => element.libelle === libelle);
    return item ? item.label : null;
  }

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Toolbar />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pt: 3,
            pb: 3,
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <img src={ImageL} alt="confetti" width={"100px"} height={"100px"} />
            <Typography
              sx={{
                mb: 3,
                fontSize: "50px",
                fontWeight: "700",
                color: "#FF7900",
              }}
            >
              Akwaba
            </Typography>
            <img src={ImageR} alt="confetti" width={"100px"} height={"100px"} />
          </Box>
          <Grid
            container
            spacing={2}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              pt: 3,
              // gap: 2,
            }}
          >
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  padding: 3,
                  boxShadow: 1,
                }}
              >
                <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                  Statistique des offres
                </Typography>
                <BarChart
                  dataset={users}
                  xAxis={[
                    {
                      scaleType: "band",
                      dataKey: "mois",
                      colorMap: {
                        type: "piecewise",
                        thresholds: [],
                        colors: ["#FC7900"],
                      },
                    },
                  ]}
                  series={[
                    {
                      dataKey: "nombreElement",
                      label: " Nombre d'utilisateurs",
                      valueFormatter,
                      color: "#FC7900",
                    },
                  ]}
                  {...chartSetting}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  padding: 3,
                  boxShadow: 1,
                }}
              >
                <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                  Statistique des utilisateurs
                </Typography>
                <Grid container spacing={1} sx={{ height: "90%" }}>
                  {offer.map((item, index) => (
                    <Grid
                      item
                      xs={24}
                      sm={12}
                      md={6}
                      key={index}
                      // sx={{ height: "50%" }}
                    >
                      <StatsCard
                        icon={getIconByLibelle(item.libelle)}
                        label={getLabelByLibelle(item.libelle)}
                        value={getOfferNombreByLibelle(item.libelle)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ color: "#001", fontSize: 22, fontWeight: 600, mb: 4 }}
          >
            Nombre d'utilisateurs par canal d'acquisition
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <List sx={style}>
                <ListItem>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      py: 1,
                      width: "100%",
                    }}
                  >
                    <Typography>Bouche à oreille :</Typography>
                    {loading ? (
                      <Skeleton width={60} />
                    ) : (
                      <Typography sx={{ fontWeight: 700 }}>
                        {getNombreByLibelle("BOUCHE_A_OREILLE")}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      py: 1,
                      width: "100%",
                    }}
                  >
                    <Typography>Télévision :</Typography>
                    {loading ? (
                      <Skeleton width={60} />
                    ) : (
                      <Typography sx={{ fontWeight: 700 }}>
                        {getNombreByLibelle("TV")}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      py: 1,
                      width: "100%",
                    }}
                  >
                    <Typography>Presse :</Typography>
                    {loading ? (
                      <Skeleton width={60} />
                    ) : (
                      <Typography sx={{ fontWeight: 700 }}>
                        {getNombreByLibelle("PRESSE")}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      py: 1,
                      width: "100%",
                    }}
                  >
                    <Typography>Commercial du terrain :</Typography>
                    {loading ? (
                      <Skeleton width={60} />
                    ) : (
                      <Typography sx={{ fontWeight: 700 }}>
                        {getNombreByLibelle("COMMERCIAL_TERRAIN")}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <List sx={style}>
                <ListItem>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      py: 1,
                      width: "100%",
                    }}
                  >
                    <Typography>Panneau publicitaire :</Typography>
                    {loading ? (
                      <Skeleton width={60} />
                    ) : (
                      <Typography sx={{ fontWeight: 700 }}>
                        {getNombreByLibelle("PANNEAU_PUBLICITAIRE")}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      py: 1,
                      width: "100%",
                    }}
                  >
                    <Typography>LinkedIn :</Typography>
                    {loading ? (
                      <Skeleton width={60} />
                    ) : (
                      <Typography sx={{ fontWeight: 700 }}>
                        {getNombreByLibelle("LINKEDIN")}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      py: 1,
                      width: "100%",
                    }}
                  >
                    <Typography>Facebook :</Typography>
                    {loading ? (
                      <Skeleton width={60} />
                    ) : (
                      <Typography sx={{ fontWeight: 700 }}>
                        {getNombreByLibelle("FACEBOOK")}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      py: 1,
                      width: "100%",
                    }}
                  >
                    <Typography>Non spécifié :</Typography>
                    {loading ? (
                      <Skeleton width={60} />
                    ) : (
                      <Typography sx={{ fontWeight: 700 }}>
                        {getNombreByLibelle(null)}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default HomePage;
