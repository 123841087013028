import CryptoJS from "crypto-js";

const ApiUrl = {
  baseUrl: "https://apirecette.jevebara.com",

  register: "api/utilisateur/register-admin",
  login: "/api/login",
  logout: "logout",
  verify: "auth/verify/",
  changePassword: "auth/change_password/",
  resetPassword: "auth/reset_password/",
};

export default ApiUrl;


export const  secretKey = "157eaf1fe2df6ced7591b01fe2d39dbfc3c0cf743ab41a4ae777335c439aed14"




