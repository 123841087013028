import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  IconButton,
  Dialog,
  DialogContent,
  Chip,
  Stack,
  Link,
} from "@mui/material";
import axios from "axios";
import Api from "../../services/http/api";
import PaginationComponent from "../../components/paginationComponent";
import CustomToast from "../../helpers/customToast";
import CloseIcon from "@mui/icons-material/Close";
import Carousel from "react-bootstrap/Carousel";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { formatDate } from "../../assets/styles/Widgets";
import { useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import { useOutletContext } from "react-router-dom";

const Badges = () => {
  const navigate = useNavigate();
  const { getNew } = useOutletContext();
  const [badges, setBadges] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [openR, setOpenR] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [currentImage, setCurrentImage] = useState(null);

  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = imageUrl.split("/").pop(); // Sets the download filename to the image's filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCarouselSelect = (selectedIndex) => {
    const image = selectedRow.fichierJoints[selectedIndex];
    setCurrentImage(image);
  };

  const handleMouseDown = useCallback(
    (event) => {
      setIsDragging(true);
      setStartPosition({
        x: event.clientX - dragPosition.x,
        y: event.clientY - dragPosition.y,
      });
    },
    [dragPosition]
  );

  const handleMouseMove = useCallback(
    (event) => {
      if (isDragging) {
        const newX = event.clientX - startPosition.x;
        const newY = event.clientY - startPosition.y;
        setDragPosition({ x: newX, y: newY });
      }
    },
    [isDragging, startPosition]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  // Reset the drag position to the center (0, 0)
  const resetPosition = () => {
    setDragPosition({ x: 0, y: 0 });
  };

  const handleNextPage = async () => {
    if (totalPages === 1) return;
    if (totalPages === page + 1) return;
    setPage(page + 1);
    setLoading(true);

    await fetchData(page);
  };

  const handlePreviousPage = async () => {
    if (page < 1) return;
    setPage(page - 1);
    setLoading(true);
    await fetchData(page);
  };

  const handleZoomIn = () =>
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Max zoom level 3
  const handleZoomOut = () =>
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 1)); // Min zoom level 1

  const handleSelectedRow = (row) => {
    setSelectedRow(row);
    setOpenR(true);
  };
  const handleValidate = (action, badgeId) => {
    validateBadge(action, badgeId);
  };
  const validateBadge = async (action, badgeId) => {
    setBadges(null);
    setLoading(true);
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/badge/admin-all/${action}/${badgeId}`,
        method: "POST",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      CustomToast.showSuccess(response.data.message);
      await fetchData(page);
    } catch (error) {
      console.log(error);
      CustomToast.showError("Opération Echoué");
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/badge?pageIndex=${page}&pageSize=50`,
        method: "GET",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      console.log(response.data.content);

      setBadges(response.data.content.content);
      setTotalPages(response.data.content.totalPages);
      getNew();
    } catch (error) {
      CustomToast.showError("Opération Echoué");
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  const grouilleurDetails = (userId) => {
    let path = `/home/grouilleur-details/`;
    navigate(path, { state: { data: userId } });
  };

  useEffect(() => {
    fetchData(page);
  }, [page]);

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Toolbar />
        <Box sx={{ width: { xs: "100%", md: "40%" }, pb: 4 }}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
                fontWeight: "700",
                fontSize: "22px",
                m: 0,
              }}
            >
              Listes des Badges{" "}
            </Box>
          </Typography>
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "65vh",
            }}
          >
            {loading || badges === null ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Grouilleur</TableCell>
                    <TableCell>Type de badge</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Date de création</TableCell>
                    <TableCell>statut</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {badges.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Link
                          onClick={() =>
                            grouilleurDetails(row.grouilleurDto.id)
                          }
                          variant="body2"
                          fontSize="16px"
                          sx={{
                            cursor: "pointer",
                            textDecoration: "none",
                            color: "black",
                            "&:hover": {
                              fontWeight: "bold",
                              color: "orange",
                            },
                          }}
                        >
                          {row.grouilleurDto.nom} {row.grouilleurDto.prenom}
                        </Link>
                      </TableCell>
                      <TableCell>{row.typeBadge}</TableCell>
                      <TableCell>
                        <Card sx={{ width: 120 }}>
                          <CardActionArea
                            onClick={() => handleSelectedRow(row)}
                          >
                            <CardMedia
                              component="img"
                              height="80"
                              image={`${Api.baseUrl}/api/file?fileReference=${row.fichierJoints[0].reference}`}
                              alt={row.nom}
                            />
                          </CardActionArea>
                        </Card>
                      </TableCell>
                      <TableCell>{formatDate(row.dateCreation)}</TableCell>
                      <TableCell>
                        <Chip color={"info"} label={row.statut} />
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              boxShadow: "none",
                              textTransform: "capitalize",
                              "&:hover": {
                                boxShadow: "none",
                              },
                            }}
                            onClick={() => handleValidate("valider", row.id)}
                          >
                            Valider
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              boxShadow: "none",
                              textTransform: "capitalize",
                              backgroundColor: "red",
                              "&:hover": {
                                backgroundColor: (theme) =>
                                  theme.palette.error.dark,
                                boxShadow: "none",
                              },
                            }}
                            onClick={() => handleValidate("invalider", row.id)}
                          >
                            Refuser
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          <Box sx={{ py: 2, px: 5 }}>
            <PaginationComponent
              page={page}
              totalPage={totalPages}
              onPreviousPage={() => {
                handlePreviousPage();
              }}
              onNextPage={() => {
                handleNextPage();
              }}
            />
          </Box>
        </Paper>
      </Box>
      <Dialog
        open={openR}
        onClose={() => setOpenR(false)}
        maxWidth="lg"
        fullScreen
      >
        <Toolbar sx={{ bgcolor: "#202124" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                right: 20,
                display: "flex",
                flexDirection: "row",
                gap: 2,
                zIndex: 1,
              }}
            >
              <IconButton
                onClick={handleZoomIn}
                aria-label="zoom-in"
                sx={{
                  color: "#FFF",
                  backgroundColor: "#333",
                  "&:hover": { backgroundColor: "#555" },
                }}
              >
                <ZoomInIcon />
              </IconButton>
              <IconButton
                onClick={handleZoomOut}
                aria-label="zoom-out"
                sx={{
                  color: "#FFF",
                  backgroundColor: "#333",
                  "&:hover": { backgroundColor: "#555" },
                }}
              >
                <ZoomOutIcon />
              </IconButton>
              <IconButton
                onClick={resetPosition}
                aria-label="reset-position"
                sx={{
                  color: "#FFF",
                  backgroundColor: "#333",
                  "&:hover": { backgroundColor: "#555" },
                }}
              >
                <RefreshIcon />
              </IconButton>
              {currentImage && (
                <IconButton
                  component="a"
                  href={`${Api.baseUrl}/api/file?fileReference=${currentImage.reference}`}
                  download={currentImage.reference}
                  aria-label="download"
                  sx={{
                    color: "#FFF",
                    backgroundColor: "#333",
                    "&:hover": { backgroundColor: "#555" },
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              )}
              <IconButton
                onClick={() => setOpenR(false)}
                aria-label="close"
                sx={{
                  color: "#FFF",
                  backgroundColor: "#333",
                  "&:hover": { backgroundColor: "#555" },
                }}
              >
                <CloseIcon sx={{ color: "#FFF" }} />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
        <DialogContent
          sx={{ bgcolor: "#202124", position: "relative", overflow: "hidden" }}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <Carousel
            style={{ maxWidth: "100%", height: "95%", overflow: "hidden" }}
            onSelect={handleCarouselSelect}
          >
            {selectedRow &&
              selectedRow.fichierJoints.map((image) => (
                <Carousel.Item key={image.reference}>
                  <Box
                    sx={{
                      textAlign: "center",
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      cursor: isDragging ? "grabbing" : "grab",
                    }}
                  >
                    <img
                      src={`${Api.baseUrl}/api/file?fileReference=${image.reference}`}
                      alt={image.typeBadge}
                      style={{
                        width: "50%",
                        objectFit: "contain",
                        transform: `translate(${dragPosition.x}px, ${dragPosition.y}px) scale(${zoomLevel})`,
                        transformOrigin: "center center",
                        transition: isDragging ? "none" : "transform 0.3s ease",
                      }}
                      onMouseDown={handleMouseDown}
                    />
                  </Box>
                </Carousel.Item>
              ))}
          </Carousel>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Badges;
