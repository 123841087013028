import React from "react";
import {
  Box,
  Toolbar,
  Typography,
  FormControl,
  InputLabel,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Backdrop,
  CircularProgress,
  MenuItem,
  // Chip,
  Select,
  TextField,
} from "@mui/material";
import GrouilleursTable from "./GrouilleursTable";
import { AppButton } from "../../../assets/styles/Widgets";
import { Icon } from "@iconify/react";
import axios from "axios";
import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { StyledMenu } from "../../../assets/styles/Widgets";
import CustomToast from "../../../helpers/customToast";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Api from "../../../services/http/api";

const Grouilleurs = () => {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [params, setParams] = useState("");
  const [data, setData] = useState(null);
  const [result, setResult] = useState(null);
  const [advanceResult, setAdvanceResult] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElExport, setAnchorElExport] = React.useState(null);
  const open = Boolean(anchorEl);
  const openExport = Boolean(anchorElExport);
  const [crittersName, setCrittersName] = useState("");
  const [categories, setCategories] = useState([]);
  const [keyValue, setKeyValue] = useState(0);
  const [villeValue, setVilleValue] = React.useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [statut, setStatut] = React.useState("");

  useEffect(() => {
    // Fetch categories from the API
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${Api.baseUrl}/api/V2/categorie`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [crittersName]);

  const handleChange = (event) => {
    const { value } = event.target;
    setCrittersName(value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubcategory(""); // Reset selected subcategory when category changes
    const selectedCategory = event.target.value;
    const selectedCategoryObj = categories.find(
      (category) => category.libelle === selectedCategory
    );
    if (selectedCategoryObj) {
      const subcategoriesForCategory = selectedCategoryObj.sousCategories;
      setSubcategories(subcategoriesForCategory);
    }
  };

  const handleChangeStatut = async (event) => {
    setStatut(event.target.value);
  };

  const handleSubcategoryChange = (event) => {
    setSelectedSubcategory(event.target.value);
  };

  const handleCritterInputChange = (event, critter) => {
    const { value } = event.target;
    switch (critter) {
      case "Ville":
        setVilleValue(value);
        setSelectedSubcategory("");
        break;
      default:
        break;
    }
  };
  const handleVileInputChange = (event) => {
    setVilleValue(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseExport = () => {
    setAnchorElExport(null);
  };
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleInputChange = (event, callback) => {
    callback(event.target.value);
  };

  const rechercheAvancer = async (page) => {
    setOpenBackdrop(true);
    // setResult(null)
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/grouilleur/search/advanced?&pageIndex=${page}&pageSize=50${
          selectedSubcategory === ""
            ? ""
            : "&sousCategorie=" + selectedSubcategory
        }${villeValue === "" ? "" : "&adresse=" + villeValue}`,

        method: "GET",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setAdvanceResult(response.data.content);
      setData(response.data.content.content);

      setOpenBackdrop(false);
    } catch (error) {
      CustomToast.showError("la connexion a échoué");
      setOpenBackdrop(false);

      console.log(error);
    } finally {
    }
  };

  const columnsToRemove = ["photoProfil", "categorie", "utilisateurid", "id"];

  const exporterListe = async () => {
    // Define the columns you want to keep
    const columnsToKeep = [
      "nom",
      "prenom",
      "adresse",
      "actif",
      "matricule",
      "photoProfil",
      "souscategorie",
      "dateCreation",
    ]; // Replace with your column names

    // Filter the data to only include the specified columns
    const filteredData = data.map((item) => {
      const filteredItem = {};
      columnsToKeep.forEach((key) => {
        if (item.hasOwnProperty(key)) {
          filteredItem[key] = item[key];
        }
      });
      return filteredItem;
    });

    // Continue with the rest of your function
    try {
      const worksheet = XLSX.utils.json_to_sheet(filteredData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Liste des Grouilleurs"
      );

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, "Liste des Grouilleurs.xlsx");
      setOpenBackdrop(false);
    } catch (error) {
      CustomToast.showError("Erreur lors du téléchargement du fichier");
      setOpenBackdrop(false);

      console.error("Erreur lors du téléchargement du fichier", error);
    } finally {
    }
  };

  const searchGrouilleur = async (page) => {
    // setAdvanceResult(null)
    if (params.trim().length === 0) {
      CustomToast.showError(
        "Veuillez saisir un nom, un matricule ou un numéro de téléphone valide"
      );
      return;
    }
    setOpenBackdrop(true);

    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/grouilleur/search/simple?pageIndex=${page}&pageSize=50&query=${params}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      console.log(response.data.content);
      // responseData = response.data.content
      setResult(response.data.content);
      setData(response.data.content.content);
      setOpenBackdrop(false);
    } catch (error) {
      CustomToast.showError("la connexion a échoué");
      setOpenBackdrop(false);

      console.log(error);
    } finally {
    }
  };

  const handleReload = () => {
    setKeyValue((keyValue) => keyValue + 1);
    setResult(null);
    setAdvanceResult(null);
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "primary.main",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Toolbar />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            pt: 3,
            pb: 5,
            gap: 2, // Add some gap between elements for smaller screens
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "40%" } }}>
            <Typography component="div">
              <Box
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  fontWeight: "700",
                  fontSize: "22px",
                  m: 0,
                }}
              >
                Liste des grouilleurs{" "}
              </Box>
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "60%" },
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 1.5,
              justifyContent: { xs: "center", md: "flex-end" },
            }}
          >
            <FormControl
              sx={{ width: { xs: "100%", md: "50%" }, ml: { md: 3 } }}
              variant="outlined"
            >
              <OutlinedInput
                sx={{
                  borderRadius: 1,
                  height: "42px",
                  "& input::placeholder": { fontSize: "14px" },
                  "&:hover": {
                    borderColor: "primary",
                  },
                }}
                id="search"
                autoComplete="email"
                placeholder="Rechercher..."
                onChange={(event) => handleInputChange(event, setParams)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search"
                      onClick={() => searchGrouilleur(0)}
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <AppButton
              id="advance-search-button"
              aria-controls={open ? "advance-search-button" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              endIcon={<Icon icon="lucide:filter" style={{ color: "white" }} />}
              sx={{
                background: "rgba(0, 8, 12, 1)",
                width: { xs: "100%", md: "auto" },
              }}
              onClick={handleClick}
            >
              Recherche Avancée
            </AppButton>
            <StyledMenu
              id="advance-search-menu"
              MenuListProps={{
                "aria-labelledby": "advance-search-menu",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem disableRipple sx={{ gap: 1 }}>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="critters-select-label">Critères</InputLabel>
                  <Select
                    labelId="critters-select-label"
                    id="critters-simple-select"
                    value={crittersName}
                    onChange={handleChange}
                    label="Critters"
                  >
                    <MenuItem value="">Sélectionner un critère</MenuItem>
                    <MenuItem value="Categories">Catégories</MenuItem>
                    {/* <MenuItem value="Sous-categories">Sous-catégories</MenuItem> */}
                    <MenuItem value="Ville">Ville</MenuItem>
                  </Select>
                </FormControl>
              </MenuItem>
              {crittersName === "Categories" && (
                <MenuItem disableRipple sx={{ gap: 1 }}>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="categories-select-label">
                      Catégories
                    </InputLabel>
                    <Select
                      labelId="categories-select-label"
                      id="categories-simple-select"
                      value={selectedCategory}
                      onChange={(event) => {
                        handleCategoryChange(event); // Call the function to handle category change
                        setSelectedCategory(event.target.value); // Update selectedCategory immediately
                      }}
                      label="Categories"
                    >
                      <MenuItem value="">Sélectionner une Catégorie</MenuItem>
                      {categories.map((category) => (
                        <MenuItem key={category.id} value={category.libelle}>
                          {category.libelle}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MenuItem>
              )}
              {crittersName === "Ville" && (
                <MenuItem disableRipple sx={{ gap: 1, m: 1 }}>
                  <TextField
                    key={`text-field-ville`}
                    id={`text-field-ville`}
                    label={`Entrer la ville`}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={villeValue}
                    onChange={(event) =>
                      handleCritterInputChange(event, crittersName)
                    }
                  />
                </MenuItem>
              )}
              {selectedCategory && (
                <MenuItem disableRipple sx={{ gap: 1 }}>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="sous-categories-select-label">
                      Sous catégories
                    </InputLabel>
                    <Select
                      labelId="subcategories-select-label"
                      id="subcategories-simple-select"
                      value={selectedSubcategory}
                      onChange={handleSubcategoryChange}
                      label="Sous-catégories"
                    >
                      <MenuItem value="">
                        Sélectionner une sous-catégorie
                      </MenuItem>
                      {subcategories.map((subcategory) => (
                        <MenuItem
                          key={subcategory.id}
                          value={subcategory.libelle}
                        >
                          {subcategory.libelle}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MenuItem>
              )}
              <MenuItem>
                <AppButton
                  sx={{ background: "#00833D", width: "100%", m: 1 }}
                  onClick={() => rechercheAvancer(0)}
                >
                  Rechercher
                </AppButton>
              </MenuItem>
            </StyledMenu>

            <AppButton
              id="export-button"
              aria-controls={open ? "export-button" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              startIcon={
                <Icon icon="uil:file-alt" style={{ color: "white" }} />
              }
              sx={{
                background: "rgba(61, 66, 63, 1)",
                width: { xs: "100%", md: "auto" },
              }}
              onClick={exporterListe}
            >
              Exporter
            </AppButton>
            <StyledMenu
              id="Exporter-menu"
              MenuListProps={{
                "aria-labelledby": "Exporter-menu",
              }}
              anchorEl={anchorElExport}
              open={openExport}
              onClose={handleCloseExport}
            >
              <MenuItem disableRipple sx={{ gap: 1 }}>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-simple-select-label">Statut</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={statut}
                    label="Statut"
                    onChange={handleChangeStatut}
                  >
                    <MenuItem value={"actif=true"}>Actif</MenuItem>
                    <MenuItem value={"actif=false"}>Désactiver</MenuItem>
                  </Select>
                </FormControl>
              </MenuItem>
              <MenuItem disableRipple sx={{ gap: 1 }}>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="categories-select-label">
                    Catégories
                  </InputLabel>
                  <Select
                    labelId="categories-select-label"
                    id="categories-simple-select"
                    value={selectedCategory}
                    onChange={(event) => {
                      handleCategoryChange(event); // Call the function to handle category change
                      setSelectedCategory(event.target.value); // Update selectedCategory immediately
                    }}
                    label="Categories"
                  >
                    <MenuItem value="">Sélectionner une Catégorie</MenuItem>
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.libelle}>
                        {category.libelle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MenuItem>
              {selectedCategory && (
                <MenuItem disableRipple sx={{ gap: 1 }}>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="sous-categories-select-label">
                      Sous catégories
                    </InputLabel>
                    <Select
                      labelId="subcategories-select-label"
                      id="subcategories-simple-select"
                      value={selectedSubcategory}
                      onChange={handleSubcategoryChange}
                      label="Sous-catégories"
                    >
                      <MenuItem value="">
                        Sélectionner une sous-catégorie
                      </MenuItem>
                      {subcategories.map((subcategory) => (
                        <MenuItem
                          key={subcategory.id}
                          value={subcategory.libelle}
                        >
                          {subcategory.libelle}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MenuItem>
              )}
              <MenuItem disableRipple sx={{ gap: 1, mx: 1 }}>
                <TextField
                  key={`text-field-ville`}
                  id={`text-field-ville`}
                  label={`Entrer la ville`}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={villeValue}
                  onChange={(event) => handleVileInputChange(event)}
                />
              </MenuItem>
              <MenuItem>
                <AppButton
                  sx={{ background: "#00833D", width: "100%", my: 1 }}
                  onClick={() => exporterListe(columnsToRemove)}
                >
                  Exporter la liste
                </AppButton>
              </MenuItem>
            </StyledMenu>
          </Box>
        </Box>

        <GrouilleursTable
          key={keyValue}
          result={result}
          advanceResult={advanceResult}
          searchHandler={searchGrouilleur}
          rechercheAvancerHandler={rechercheAvancer}
          setResult={setResult}
          reload={handleReload}
        />
      </Box>
    </>
  );
};
export default Grouilleurs;
