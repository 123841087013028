import React, { useState } from "react";
import { Box, Typography, Link } from "@mui/material";
import ReviewCell from "./ReviewCell";

const Review = ({ avis }) => {
  const [visibleCount, setVisibleCount] = useState(4);

  const handleShowMore = () => {
    if (visibleCount >= avis.length) {
      setVisibleCount(4); // Reset to initial count if all items are shown
    } else {
      setVisibleCount((prevCount) => prevCount + 4); // Load 4 more items
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ fontWeight: "500", pt: 4 }}>
        Avis
      </Typography>
      {avis.length === 0 ? (
        <Typography sx={{ fontWeight: "400", py: 2 }}>Aucun avis.</Typography>
      ) : (
        <>
          {avis.slice(0, visibleCount).map((item, index) => (
            <ReviewCell key={index} avis={item} />
          ))}
          {avis.length > 4 && (
            <Box sx={{ textAlign: "left", mt: 4 }}>
              <strong>
                <Link
                  onClick={handleShowMore}
                  variant="body2"
                  fontWeight="bold"
                  fontSize="16px"
                  sx={{ cursor: "pointer" }}
                >
                  {visibleCount >= avis.length ? "voir moins" : "voir plus"}
                </Link>
              </strong>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Review;
