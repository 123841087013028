import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
} from "@mui/material";
import Api from "../../../services/http/api";

export default function Realisation({ images, handleOpen }) {
  return (
    <Box sx={{ backgroundcolor: "white" }}>
      <Box sx={{ my: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "500", mb: 2 }}>
          Réalisations
        </Typography>
      </Box>
      <ImageList
        sx={{
          width: { xs: "100%", md: "1000px" },
          borderRadius: "10px",
          maxHeight: 600,
          // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
          transform: "translateZ(0)",
        }}
        cols={3}
        rowHeight={200}
        gap={4}
      >
        {images.map((item, index) => {
          const rows = 1;
          const cols = 2;
          return images.length === 0 ? (
            <Typography sx={{ fontWeight: "400", py: 2 }}>
              Aucune Réalisation.
            </Typography>
          ) : (
            <ImageListItem key={item.referenceFichier} rows={rows}>
              <Card elevation={0} sx={{ width: "100%", borderRadius: "10px" }}>
                <CardActionArea onClick={() => handleOpen(index)}>
                  <CardMedia
                    component="img"
                    height={250}
                    width={200}
                    image={`${Api.baseUrl}/api/file?fileReference=${item.referenceFichier}`}
                    alt={item.referenceFichier}
                  />
                </CardActionArea>
              </Card>
              <ImageListItemBar
                sx={{
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                }}
                position="bottom"
                actionIcon={
                  <IconButton sx={{ color: "white" }} aria-label={`star`}>
                    <ThumbUpIcon />
                  </IconButton>
                }
                actionPosition="left"
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </Box>
  );
}
